












































import Vue from "vue";
export default Vue.extend({
  props: ["title", "buttonLabel", "buttonIcon", "buttonCondition"],
  data() {
    return {
      valid: false,
      loading: false,
      error: ""
    };
  },
  methods: {
    set(key, value) {
      this.$emit("update:" + key, value);
    },
    async submit() {
      this.error = "";
      this.loading = true;
      this.$emit("submit", err => {
        this.error = err;
        this.loading = false;
      });
    }
  },
  created() {}
});
